<template>

  <div>
    <v-card-title>
      <v-row>
          <v-col cols="8" sm="12">
          <breadcrumbs :items="dataBreadcrumbs" />
          </v-col>
            <v-col cols="4" sm="12" align="right">
            </v-col>
        </v-row>
      <v-row>
        <v-col xs="12" class="col-xs-12">
          <h2 style="font-size: 25px !important;">Coûts Supplémentaires</h2>
        </v-col>
        <v-col xs="12" class="col-xs-12">
          <v-dialog
              v-model="dialog"
              max-width="500px"
          >
          <template v-slot:activator="{ on, attrs }">
                  <v-btn
                  color="primary"
                  dark
                  v-bind="attrs"
                  v-on="on"
                  class="mx-2 float-right">
                    <v-icon small>
                      mdi-plus
                    </v-icon>
                    Nouveau
                  </v-btn>
                </template>
            <v-card>
              <v-card-title>
                <span class="text-h5">{{ formTitle }}</span>
                <v-spacer></v-spacer>
              </v-card-title>

              <v-form @submit.prevent="save">
                <v-card-text>
                  <v-container>
                    <v-row>
                      <v-col
                          cols="12"
                          sm="12"
                          md="12"
                      >
                        <v-text-field
                            v-model="editedItem.description"
                            outlined
                            hide-details
                            label="Libellé du Coût"
                        ></v-text-field>
                      </v-col>
                      <v-col
                          cols="12"
                          sm="6"
                          md="6"
                      >
                        <v-text-field
                            v-model="editedItem.weight"
                            outlined
                            hide-details
                            label="Valeur du poids"
                        ></v-text-field>
                      </v-col>
                      <v-col
                          cols="12"
                          sm="6"
                          md="6"
                      >
                        <v-text-field
                            v-model="editedItem.price"
                            outlined
                            hide-details
                            label="Coût à appliquer"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>

                <v-card-actions>
                  <v-btn
                    color="error"
                    text
                    @click="close"
                  >
                    Fermer
                  </v-btn>
                  <v-spacer></v-spacer>
                  <v-btn
                      :loading="loading"
                      :disabled="loading"
                      color="primary"
                      dark
                      type="submit"
                  >
                    <v-icon
                      left
                      dark
                    >
                      mdi-content-save-check
                    </v-icon>
                    Enregistrer
                  </v-btn>
                </v-card-actions>
              </v-form>
            </v-card>
          </v-dialog>
        </v-col>
      </v-row>
    </v-card-title>
    <v-dialog v-model="dialogDelete" max-width="500px">
      <v-card>
        <v-card-title class="text-h5">Êtes-vous sûr de bien vouloir supprimer ?</v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="error" text @click="closeDelete">Annuler</v-btn>
          <v-btn color="success" text @click="deleteItemConfirm">Ok</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- actions -->
    <v-card-text class="d-flex align-center flex-wrap pb-0">
      <!-- search -->
      <v-row>
        <v-col lg="6" md="6" sm="12" xs="12">
          <v-text-field v-model="searchQuery" label="Filtre"></v-text-field>
        </v-col>
        <!-- v-col lg="2">
          <v-select
              label="Statut"
              v-model="search.status"
              :items="statues"
              item-value="id"
              item-text="label"
              @change="fetchItems"
          ></v-select>
        </v-col -->
        <v-col lg="3" md="6" sm="6" xs="6">
          <v-menu
              v-model="menu2"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                  v-model="interval.date1"
                  label="Du"
                  prepend-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
                v-model="interval.date1"
                @input="menu2 = false"
            ></v-date-picker>
          </v-menu>
        </v-col>
        <v-col lg="3" md="6" sm="6" xs="6">
          <v-menu
              v-model="menu3"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                  v-model="interval.date2"
                  label="Au"
                  prepend-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
                v-model="interval.date2"
                @input="menu3 = false"
            ></v-date-picker>
          </v-menu>
        </v-col>

      </v-row>

      <v-spacer></v-spacer>

      <div class="d-flex align-center flex-wrap">
          <v-alert v-if="alert.active" :type="alert.color">{{alert.text}}</v-alert>
      </div>
    </v-card-text>
    <v-data-table
        :loading="loading"
        :headers="headers"
        :items="listCosts.listEntity"
        class="elevation-0"
        :page.sync="listCosts.current_page"
        :items-per-page="listCosts.per_page"
        :sort-by="['id']"
        @page-count="pageCount = $event"
        hide-default-footer
        :sort-desc="true"
    >
      <template v-slot:item.actions="{ item }">
        <v-btn
          right
          color="warning"
          dark
          class="mx-1 text-right"
          @click="editItem(item)"
      >
        <v-icon
              small
          >
          mdi-pencil
        </v-icon>
      </v-btn>
        <v-btn
          right
          color="error"
          dark
          class="mx-1 text-right"
          @click="deleteItem(item)"
          
      >
        <v-icon
            small
        >
          mdi-delete
        </v-icon>
        </v-btn>
      </template>
    </v-data-table>
    <v-card-text class="pt-2">
      <v-row>
        <v-col
            lg="2"
            cols="3"
        >

          <v-select
              v-model="listCosts.per_page"
              label="Lignes par page:"
              :items="[10,20,30,50,80,100, 'Toutes']"
              hide-details
              @change="fetchItems()"
          ></v-select>

        </v-col>

        <v-col
            lg="10"
            cols="9"
            class="d-flex justify-end"
        >
          <v-pagination
              v-model="listCosts.current_page"
              total-visible="6"
              :length="parseInt(listCosts.pagination )"
              @input="fetchItems()"
          ></v-pagination>
        </v-col>
      </v-row>
    </v-card-text>
  </div>

</template>

<script>
import {mdiAlertRemove, mdiThumbUp} from '@mdi/js'
import {Drequest} from "../../plugins/Drequest";
import breadcrumbs from "../../components/ui/breadcrumbs";
const moonLanding = new Date();

export default {
  name: "supplement",
  data: () => ({
    dataBreadcrumbs: [],
    interval: {
      date1: moonLanding.getFullYear() + "-01-01",
      date2: moonLanding.getFullYear() + "-12-31"
    },
    searchQuery: "",
    menu2: false,
    menu3: false,
    icons: {
      mdiAlertRemove,
      mdiThumbUp
    },
    alert: {
      color: '',
      text: '',
      active: false
    },
    loading: false,
    dialog: false,
    dialogDelete: false,
    headers: [
      {
        text: 'Description',
        align: 'start',
        sortable: false,
        value: 'description',
      },
      {text: 'Poids supplémentaire', value: 'weight'},
      {text: 'Coût supplémentaire', value: 'price'},
      {text: 'Actions', value: 'actions', sortable: false},
    ],
    listCosts: [],
    editedIndex: -1,
    editedItem: {
      description: '',
      weight: 0,
      price: 0
    },
    defaultItem: {
      description: '',
      weight: 0,
      price: 0
    },
  }),
  components: {
    breadcrumbs
  },
  computed: {
    formTitle() {
      return this.editedIndex === -1 ? 'Nouveau coût supplément' : 'Éditer un coût supplément'
    },
  },

  watch: {
    dialog(val) {
      val || this.close()
    },
    dialogDelete(val) {
      val || this.closeDelete()
    },
  },

  created() {
    this.dataBreadcrumbs = [
      {
        text: 'Accueil',
        disabled: false,
        href: '/dashboard',
      },
      {
        text: 'Suppléments',
        disabled: true,
        href: '/settings/course',
      }
    ];
    this.initialize()
    this.fetchItems();
  },

  methods: {
    fetchItems() {
      this.search = {
        'tree_item.name:in': [],
      }
      this.loading = true;
      Drequest.api("lazyloading.supplementprice?dfilters=on&transporter.id:eq=" + this.$store.state.transporter.id)
          .param({
            next: this.listCosts.current_page,
            per_page: this.listCosts.per_page,
          })
          .get((response) => {
            console.log(response);
            this.listCosts = response;
            this.items = this.listCosts.listEntity;

            this.loading = false;
          })
    },
    initialize() {
      this.loading = true;
      this.$store.dispatch('loadCost', {supplementprice: this.editedItem}).then((res) => {
        this.listCosts = res;
        this.alert.active = false;
        this.loading = false;
      })
    },

    editItem(item) {
      this.editedIndex = this.listCosts.listEntity.indexOf(item);
      this.editedItem = {
          id: item.id,
          weight: item.weight,
          description: item.description,
          "transporter.id": item.transporter.id,
          price: item.price
        }
      this.dialog = true
    },

    deleteItem(item) {
      this.editedIndex = this.listCosts.listEntity.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialogDelete = true
    },

    deleteItemConfirm() {
      this.loading = true
      Drequest.api("delete.supplementprice?id=" + this.editedItem.id)
          .get((response) => {
            console.log(response)
            this.editedItem = Object.assign({}, this.defaultItem)
            this.listCosts.listEntity.splice(this.editedIndex, 1)
            this.closeDelete()
            this.loading = false
          })
    },

    close() {
      this.dialog = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },

    closeDelete() {
      this.dialogDelete = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },

    save() {
      this.loading = true;
      if (this.editedIndex > -1) {
        const promise = this.$store.dispatch('updateCost', {
          id: this.editedItem.id,
          weight: this.editedItem.weight,
          description: this.editedItem.description,
          "transporter.id": this.editedItem["transporter.id"],
          price: this.editedItem.price
        })
        promise.then((res) => {
          console.log(res)
          // this.listCosts[this.editedIndex] = res.supplementprice
          this.initialize()
        })
            .catch(err => {
              this.alert = {
                color: "error",
                active: true,
                text: "Erreur lors de l'enregistrement du supplément"
              }
              console.log(err)
            })

      } else {
        const promise = this.$store.dispatch('saveCost', {
          supplementprice: {
            // id: this.editedItem.id,
            weight: this.editedItem.weight,
            description: this.editedItem.description,
            price: this.editedItem.price
          }
        })
        promise.then((res) => {
          console.log(res)
          this.alert = {
            color: "success",
            active: true,
            text: res.supplementprice.label,
            icons: this.icons.mdiThumbUp
          }
          this.initialize()
        })
            .catch(err => {
              this.alert = {
                color: "error",
                active: true,
                text: "Erreur lors de l'enregistrement du supplément",
                icons: this.icons.mdiAlertRemove
              }
              console.log(err)
            })
      }
      this.loading = false;
      this.close()
    },
  },
}
</script>
<style scoped>

.v-btn:not(.v-btn--round).v-size--default {
  height: 30px;
  width: auto;
  font-weight: 600;
  padding: 10px;
  color: white;
}

.interval-relaypoint-table {
  margin: 0 5%;
}

.v-card__title {
  display: revert !important;
}
</style>